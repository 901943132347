import Typography from "typography"
import noriegaTheme from 'typography-theme-noriega'

noriegaTheme.baseLineHeight =  1.666;
noriegaTheme.headerFontFamily = ['Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'];
noriegaTheme.baseFontSize = '18px';

const typography = new Typography(
  noriegaTheme
);
//import Wordpress2016 from "typography-theme-wordpress-2016"

//Wordpress2016.overrideThemeStyles = () => {
//  return {
//    "a.gatsby-resp-image-link": {
//      boxShadow: `none`,
//    },
//  }
//}

//delete Wordpress2016.googleFonts

//const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
//if (process.env.NODE_ENV !== `production`) {
//  typography.injectStyles()
//}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
